import React, { useRef, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { CustomHtmlPrompt } from '../../../components/inputs';

const AdditionalInfo = ({ additionalInfo, moreText = 'More' }) => {
  const reactTooltipRef = useRef(null);
  const reactTooltipContentRef = useRef(null);
  const reactTooltipButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // Handle keyboard interactions
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isOpen) return;

      if (e.key === 'Escape') {
        e.preventDefault();
        ReactTooltip.hide();
      }

      if (e.key === 'Tab') {
        // Get all focusable elements in the dialog
        const dialog = reactTooltipContentRef.current;
        const focusableElements = dialog?.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        );
        const firstFocusable = focusableElements?.[0];
        const lastFocusable = focusableElements?.[focusableElements.length - 1];

        // If shift+tab and on first element, move to last
        if (e.shiftKey && document.activeElement === firstFocusable) {
          e.preventDefault();
          lastFocusable?.focus();
        }
        // If tab and on last element, move to first
        else if (!e.shiftKey && document.activeElement === lastFocusable) {
          e.preventDefault();
          firstFocusable?.focus();
        }
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <React.Fragment>
      {' '}
      <button
        data-tip
        data-event="click"
        data-for="react-tooltip-addi-info"
        aria-expanded={isOpen}
        aria-controls="react-tooltip-addi-info-content"
        aria-label="More information"
        className="info-btn"
        type="button"
        ref={reactTooltipButtonRef}
      >
        {moreText}
      </button>
      <ReactTooltip
        type="light"
        effect="solid"
        place="bottom"
        border
        globalEventOff="click"
        clickable
        id="react-tooltip-addi-info"
        afterShow={() => {
          setIsOpen(true);
          setTimeout(() => {
            reactTooltipContentRef.current?.focus();
          }, 5);
        }}
        afterHide={() => {
          setIsOpen(false);
          setTimeout(() => {
            reactTooltipButtonRef.current?.focus();
          }, 5);
        }}
        className="additional-info-box"
        ref={reactTooltipRef}
        overridePosition={(
          { left, top },
          currentEvent,
          currentTarget,
          node,
          place,
          desiredPlace,
          effect,
          offset,
        ) => {
          const rect = node.getBoundingClientRect();
          const screenWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          const screenHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          if (top < 10) {
            top = 10;
          } else if (top + rect.height > screenHeight) {
            top = screenHeight - rect.height - 10;
          }

          if (left < 10) {
            left = 10;
          } else if (left + rect.width > screenWidth) {
            left = screenWidth - rect.width - 10;
          }

          return { left, top };
        }}
      >
        <div
          role="dialog"
          aria-modal="true"
          tabIndex={-1}
          aria-label="More information"
          ref={reactTooltipContentRef}
        >
          <button
            type="button"
            className="close-tooltip fa fa-close"
            aria-label="Close more information"
            onClick={() => {
              reactTooltipRef.current.tooltipRef = null;
              ReactTooltip.hide();
            }}
          />
          <div
            className="addi-info-content"
            id="react-tooltip-addi-info-content"
          >
            <CustomHtmlPrompt label={additionalInfo} />
          </div>
        </div>
      </ReactTooltip>
    </React.Fragment>
  );
};

export default AdditionalInfo;
