import React from 'react';

const PlainMessage = ({ message }) => (
  <div
    className="w-100 word-wrap-break-word"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: message || '' }}
  />
);

export default PlainMessage;
